import React, { useEffect, useState } from "react";

type Props = {};

const words = ["teamwork", "collaboration", "persistence", "curiosity"];

const WordRoll = (props: Props) => {
  const [word, setWord] = useState(words[0]);
  useEffect(() => {
    let num = 0;
    setInterval(() => {
      num = num + 1 > words.length - 1 ? 0 : num + 1;

      setWord(words[num]);
    }, 1000);
  }, []);

  const getWord = () => {
    return words[1];
  };

  return (
    <div className="p-8 bg-slate-800 text-slate-100 text-4xl">
      <div className="container mx-auto text-center">
        <span>Success is achieved through </span>
        <div className="w-64 text-left inline-block relative">
          <b className="z-10 relative text-slate-300">{word}</b>
          <div className="z-0 absolute w-64 h-1 top-10 bg-amber-200"></div>
        </div>
      </div>
    </div>
  );
};

export default WordRoll;
